import React, { useEffect, useRef, useState } from 'react';
import {aboutPage, aboutHeader, ingSubHead,chooseWisely,ingRedeemMethodContainer,imgRedeemContainerWrapper,icon,ingImageContainer ,ingHeaderWrapper,ingPageContentWrapper,ingMarketStatus,ingMarketStatusText,ingCookLink,ingRedeemContainer, closed, closedRed} from '../styles/layout/ingredients/index.module.scss';
import {errorText,errorBtn} from '../styles/layout/frycooks/index.module.scss'
import {faqContainer, faqSection, faqHeader, faqText} from '../styles/layout/faq/index.module.scss';
import {bg,video} from '../styles/layout/frycooks/index.module.scss'
import { useStaticQuery, graphql } from 'gatsby';
import {Link} from 'gatsby';
import IngRedeem from '../components/composite/ingRedeemButton';
import vid from '../images/ingprev2.mp4';
import {INGREDIENTS_ABI,INGREDIENTS_ADDRESS} from '../constants/contractAddr';
import Trash from '../components/base/svg/trash';
import Cook from '../components/base/svg/cook';
import Banner from '../components/composite/banner';

const MarketClosed = () => (<p className={ingMarketStatusText}>The market is currently closed! <Link className={ingCookLink} to='/frycooks'>Mint Your Frycooks</Link> and get ready for the next time it opens up! </p>
)


const MarketOpen = () => (<p className={ingMarketStatusText}>I hope you're hungry! Ingredients are used to mint sudoburgers in phase 3! Would you like to dive in the dumpster or redeem your frycook? </p>
)

const CookRedeem = () => ( <IngRedeem method="Redeem"> 
    <Cook className={icon}/>
    </IngRedeem>); 

const DumpsterRedeem = () => ( <IngRedeem method="Dumpster"> 
<Trash className={icon}/>
</IngRedeem>); 

const Closed = () => (<p className={closed}>Closed</p>);


const Ingredients = () => {

  const MARKET_STATUS = {
    OPEN: "open",
    CLOSED: "closed",
    LOADING: "loading",
    ERROR: 'error'
  }

  const WalletErrors = {
    NO_WEB3: "Error loading Web3",
    NETWORK_ERROR: "Network error try again",
    METAMASK_CONNECT_ERROR: "Error connecting to Metamask",
    NO_WALLET_AVAILABLE: "No wallet available",
    UNKNOWN_ERROR: "Unknown error refresh and try again",
    PROVIDER_NO_CHAIN_CONNECT: "Web3 provider not connected to chain",
    ADOPT_ERROR: "Couldn't mint verify you have enough eth to cover gas fees",
    NO_MAINNET: "Not connected to Mainnet",
    CONTRACT_INTERACTION_ERROR: "Contract interaction error",
    ERROR_FETCHING_MARKET: "Error fetching market status, please refresh"
};


   
      interface walletState {
        address: string | null;
        network: string | null;
        errorMessage: string | null;
        web3Loaded: boolean;
        totalSupply: number | null;
        readyToPurchase: boolean;
        marketOpen: string
     
      }
      
      const initialWalletState = {
          address: null,
          network: null,
          errorMessage: null,
          web3Loaded: false,
          totalSupply: null,
          readyToPurchase: false,
          marketOpen: MARKET_STATUS.LOADING
        
        }
      
    const [walletState, setWalletState] = useState<walletState>(initialWalletState);

    const requireWeb3 = () => {

      return new Promise<void>((resolve, reject)=> {
        if('web3' in window && 'ethereum' in window){
          if(window.ethereum.isConnected()){
            resolve();
          }else {
            reject(WalletErrors.PROVIDER_NO_CHAIN_CONNECT)
          }
      }else{
          reject(WalletErrors.NO_WEB3);
      }
      })
    };

    const  getNetwork = () => {
        return new Promise<string>((resolve, reject) => {
         window.ethereum.request({ method: 'eth_chainId' }) .then((response:string) => {

            let networkString = "";

            switch(response){
              case "0x1": {networkString="Mainnet"; break;}
              case "0x3": {networkString="Ropsten Test Network"; break;}
              case "0x4": {networkString="Rinkeby Test Network"; break;}
              case "0x5": {networkString="Goerli Test Network"; break;}
              case "0x2a": {networkString="Kovan Test Network"; break;}
              default: {networkString=""; break;}
            }
            setWalletState(prevState => ({...prevState, network:networkString}))
            
            if(networkString !="Mainnet"){
              reject(WalletErrors.NO_MAINNET)
            }

            resolve(networkString)}).catch(()=>{
                        reject(WalletErrors.NETWORK_ERROR);
            })
        })
      }
    

    const connectMetaMask =  (network:string)=> {
        return new Promise<string>((resolve, reject)=>{
        if (window.ethereum) {
           window.ethereum.request({ method: 'eth_requestAccounts' }).then(()=>{
            if(window.ethereum.selectedAddress){
              resolve(network);
            }else{
              reject(WalletErrors.NO_WALLET_AVAILABLE);
            }
           }).catch(()=>{
             reject(WalletErrors.METAMASK_CONNECT_ERROR);
           })
        } else {
          reject(WalletErrors.NO_WALLET_AVAILABLE);
        }
        });      
    }

 
    const updateWalletInfo = (network:string) => {
      return new Promise<string>((resolve,reject)=>{
        if(window.ethereum.selectedAddress){
            setWalletState( prevState =>({...prevState,address: window.ethereum.selectedAddress, readyToPurchase:true}))
        } else {
          reject(WalletErrors.NO_WALLET_AVAILABLE)
        }
        resolve(network);
      });
    };

    const fetchMarketStatus = () => {
      return new Promise<string>(async (resolve,reject)=> {
        try {
          const ingContract = new window.web3.eth.Contract(INGREDIENTS_ABI, INGREDIENTS_ADDRESS)

          const isMarketOpen = await ingContract.methods.getMarket().call()
          
          const marketOpen = isMarketOpen ? MARKET_STATUS.OPEN : MARKET_STATUS.CLOSED;
          
          setWalletState(prevState => ({...prevState, marketOpen}))
          resolve(marketOpen);
          
        } catch (error) {
          console.log(error)
          reject(WalletErrors.ERROR_FETCHING_MARKET)
        }
      })
    };

    const loadUser =()=>{
      requireWeb3()
      .then(getNetwork)
      .then(connectMetaMask)
      .then(updateWalletInfo)
      .then(fetchMarketStatus)
      .catch((e)=> {setWalletState(prevState => ({...prevState, errorMessage: e, readyToPurchase:false, marketOpen: MARKET_STATUS.ERROR}))})
};

    useEffect(()=>{
      const loadFn = () => {
        setWalletState(prevState => ({...prevState, web3Loaded: true}))
       }
       if('web3' in window && 'ethereum' in window){
        loadFn();
      }else{
        const script = document.getElementById("web3")!;
        script.addEventListener('load',loadFn)
      }
    },[]);

    useEffect(()=>{
      if (walletState.web3Loaded && window.ethereum) {
            if(window.Web3 && window.Web3.givenProvider){
            window.web3 = new window.Web3(window.Web3.givenProvider)
            loadUser();
      
            window.ethereum.on('accountsChanged', ()=> {
              console.log("ACCOUNT CHANGED")
              setWalletState(prevState=>({...prevState, errorMessage:null}))
             loadUser();
            })

            window.ethereum.on('chainChanged', ()=> {
              console.log("CHAIN CHANGED")
              setWalletState(prevState=>({...prevState, errorMessage:null}))
             loadUser();
            })
          }else{
            setWalletState(prevState =>({...prevState, errorMessage:WalletErrors.NO_WEB3}))
          }
      }
    },[walletState.web3Loaded]);

  



const data = useStaticQuery(graphql`
query ingFaq {
  when:contentfulFaq(question: {eq: "When can we mint Sudoburgers?"})  {
    question
    answerShort {
    answerShort
    }
  }
  
  howMany: contentfulFaq(question: {eq: "How many ingredients are there?"})  {
    question
    answerShort {
    answerShort
    }
  }
}
 
  `)

  const videoRef = useRef<HTMLVideoElement>(null);

  const play = ()=> {
    videoRef.current?.play();
  };


  useEffect(()=>{
    play();
 },[]);

{
    return (
        <>
        <div className={bg}>
        <video
                 ref={videoRef}
                 className={video}
                 loop
                 muted={true}
                 playsInline={true}
               >
                <source src={vid} type="video/mp4" />
               </video>         
        </div>
        <div className={aboutPage}>
            <div className={ingHeaderWrapper}>
                <h5 className={ingSubHead}>Sudoburger phase 2</h5>
            <h2 className={aboutHeader}>
          Ingredients
        </h2>
            </div>
            <div className={ingPageContentWrapper}>
                <h6 className={`${ingMarketStatus} ${walletState.marketOpen === MARKET_STATUS.CLOSED || walletState.marketOpen === MARKET_STATUS.CLOSED  ?   closedRed : ""}`}>Ingredients Market Status:</h6>
                <h6 className={`${ingMarketStatus} ${walletState.marketOpen === MARKET_STATUS.CLOSED || walletState.marketOpen === MARKET_STATUS.ERROR  ? closedRed : ""}`}>{walletState.marketOpen}</h6>
                {walletState.marketOpen === MARKET_STATUS.OPEN && <MarketOpen/> }
                {walletState.marketOpen === MARKET_STATUS.CLOSED && <MarketClosed/> }
                <div className={imgRedeemContainerWrapper}>
                    <div className={ingRedeemContainer}>
                        <div className={ingRedeemMethodContainer}>
                            <div className={ingImageContainer}>
                                {walletState.marketOpen === MARKET_STATUS.OPEN && !walletState.errorMessage
                                ? (  <Link to="/cookredeem">
                                    <CookRedeem/>
                                </Link>) 
                                :( <>
                                <CookRedeem/>
                                </>)}
                            </div>
                        {walletState.marketOpen === MARKET_STATUS.CLOSED &&  <Closed/> } 
                        </div>
                        <div className={ingRedeemMethodContainer}>
                        <div className={ingImageContainer}>
                        {walletState.marketOpen === MARKET_STATUS.OPEN  && !walletState.errorMessage
                                ? (  <Link to="/dumpsterredeem">
                                <DumpsterRedeem/>
                                </Link>) 
                                :(<DumpsterRedeem/>)}
                            </div>  
                            {walletState.marketOpen === MARKET_STATUS.CLOSED &&  <Closed/> } 
                        </div>
                    </div>
                    {walletState.errorMessage &&
              <>  
                <p className={errorText}>{walletState.errorMessage}</p>
                <p className={errorText}>Verfify your wallet settings and refresh to reconnect</p>
          
              </>}
                    {walletState.marketOpen === MARKET_STATUS.OPEN && !walletState.errorMessage &&  <p className={chooseWisely}>Choose Wisely</p> }
                </div> 

                <div className={faqContainer}>
              
                <div className={faqSection}>
                <h4 className={faqHeader}>
                {data.howMany.question}
                </h4>
                <p className={faqText}>{data.howMany.answerShort.answerShort}</p>
                </div>
        
        
                <div className={faqSection}>
                <h4 className={faqHeader}>
                {data.when.question}
                </h4>
                <p className={faqText}>{data.when.answerShort.answerShort}</p>
                </div>
                </div>
            </div>
        </div>
        </>
       
    );
};
}

export default Ingredients;