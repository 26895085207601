// extracted by mini-css-extract-plugin
export var aboutPage = "index-module--aboutPage--10vog";
export var fadein = "index-module--fadein--_pzIX";
export var aboutHeader = "index-module--aboutHeader--2dzT8";
export var aboutText = "index-module--aboutText--u9Is2";
export var aboutEmail = "index-module--aboutEmail--292Zt";
export var ingHeaderWrapper = "index-module--ingHeaderWrapper--1NSzu";
export var ingSubHead = "index-module--ingSubHead--2-Jey";
export var ingPageContentWrapper = "index-module--ingPageContentWrapper--wdVhe";
export var ingMarketStatus = "index-module--ingMarketStatus--LKSvI";
export var ingMarketStatusText = "index-module--ingMarketStatusText--15YDK";
export var ingCookLink = "index-module--ingCookLink--3pevo";
export var ingRedeemContainer = "index-module--ingRedeemContainer--lBG3x";
export var imgRedeemContainerWrapper = "index-module--imgRedeemContainerWrapper--2M6ql";
export var ingRedeemMethodContainer = "index-module--ingRedeemMethodContainer--CldIa";
export var ingImageContainer = "index-module--ingImageContainer--2Ebng";
export var chooseWisely = "index-module--chooseWisely--2b1Bn";
export var closed = "index-module--closed--acfxv";
export var closedRed = "index-module--closedRed--3ef1n";
export var icon = "index-module--icon--34Dot";