import React from 'react';
import {container, redeemButton} from './index.module.scss';
const ImgRedeem = ({children, method }: {children: React.ReactElement, enabled?:boolean, method:string}) => {


    return (
        <div className={container}>
            {children}
            <button className={redeemButton}>{method}</button>
        </div>
    );
}


export default ImgRedeem;