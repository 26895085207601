// extracted by mini-css-extract-plugin
export var frycooks = "index-module--frycooks--qSJzD";
export var fadein = "index-module--fadein--4i_lI";
export var mintBtn = "index-module--mintBtn--1lUw1";
export var video = "index-module--video--3KCR4";
export var bg = "index-module--bg--3imYh";
export var fryCookHeader = "index-module--fryCookHeader--3Jmue";
export var fryCookText = "index-module--fryCookText--1elGU";
export var incrementBtn = "index-module--incrementBtn--O-9UZ";
export var btnDisabled = "index-module--btnDisabled--3WqDF";
export var arrowContainer = "index-module--arrowContainer--1b1f0";
export var count = "index-module--count--2CKFR";
export var mint = "index-module--mint--33OIt";
export var mintAdopt = "index-module--mintAdopt--Vmbzc";
export var remaining = "index-module--remaining--2Rahn";
export var featuresContainer = "index-module--featuresContainer--3KxCd";
export var featuresSvgContainer = "index-module--featuresSvgContainer--1lhcE";
export var featuresElement = "index-module--featuresElement--3Fixg";
export var featuresText = "index-module--featuresText--1E-j7";
export var etherscan = "index-module--etherscan--2RbLV";
export var arrow = "index-module--arrow--3rlbW";
export var content = "index-module--content--3bObf";
export var background = "index-module--background--3l-cq";
export var errorText = "index-module--errorText--BA8GW";
export var errorBtn = "index-module--errorBtn--1cQ4_";
export var spinnerContainer = "index-module--spinnerContainer--2tsVA";
export var noCooksContainer = "index-module--noCooksContainer--2Rjpb";
export var numSelectedContainer = "index-module--numSelectedContainer--12aa3";
export var hideRedeemedContainer = "index-module--hideRedeemedContainer--1JUFq";
export var selectedAmt = "index-module--selectedAmt--37M3z";
export var hideRedeemedLabel = "index-module--hideRedeemedLabel--R3Vfy";
export var sadFaceContainer = "index-module--sadFaceContainer--1nvYA";
export var noCooksTextContainer = "index-module--noCooksTextContainer--3JmEM";
export var noCooksText = "index-module--noCooksText--2x5XQ";
export var noCookLink = "index-module--noCookLink--2d-ng";